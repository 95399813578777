import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import * as React from "react"

import type { IconName } from "@/app/components/ui/icons/name"
import { cn } from "@/app/utils/misc"

import * as gtag from "../../utils/gtag.client"
import { Icon } from "./icons/icon"

const defaultButtonClass = `hc-button`
const buttonVariants = cva(`button`, {
	variants: {
		variant: {
			default: ``,
			unstyled: `unstyled`,
			cta: `${defaultButtonClass} cta`,
			withIcon: `${defaultButtonClass} with-icon`,
			ghost: `${defaultButtonClass} ghost`,
			secondary: `${defaultButtonClass} secondary`,
			highlight: `${defaultButtonClass} highlight`,
			destructive: `${defaultButtonClass} destructive`,
			icon: `${defaultButtonClass} icon`,
			iconCta: `${defaultButtonClass} icon cta`,
			iconCtaSecondary: `${defaultButtonClass} icon cta-secondary`,
			iconGhost: `${defaultButtonClass} icon ghost`,
			iconSecondary: `${defaultButtonClass} icon secondary`,
			iconHighlight: `${defaultButtonClass} icon highlight`,
			iconDestructive: `${defaultButtonClass} icon destructive`,
			link: `${defaultButtonClass} link`,
			inlineLink: `${defaultButtonClass} inline-link`,
		},
		shape: {
			default: ``,
			rounded: `rounded`,
			block: `block`,
			blockConstrained: `block constrained`,
		},
		group: {
			left: `group-left`,
			middle: `group-middle`,
			right: `group-right`,
		},
		size: {
			default: ``,
			normal: ``,
			xs: `x-small`,
			sm: `small`,
			lg: `large`,
			fullHeight: `full-height`,
		},
	},
	defaultVariants: {
		variant: `default`,
		size: `default`,
	},
})

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
	icon?: IconName
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			variant,
			size,
			shape,
			group,
			asChild = false,
			onClick,
			children,
			icon,
			...props
		},
		ref,
	) => {
		const handleClick = (
			event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		) => {
			if (onClick) {
				onClick(event)
			}
			gtag.event({
				action: `click`,
				category: `button`,
				label: props.id ? props.id : `buttonNoId`,
			})
		}

		const Comp = asChild ? Slot : `button`

		const content =
			variant === `withIcon` ? (
				<>
					<span>{children}</span>
					{icon ? (
						<span>
							<Icon name={icon} />
						</span>
					) : null}
				</>
			) : (
				children
			)

		return (
			<Comp
				className={cn(
					buttonVariants({ variant, size, shape, group, className }),
					`disabled:cursor-not-allowed disabled:opacity-50`,
				)}
				ref={ref}
				onClick={handleClick}
				{...props}
			>
				{content}
			</Comp>
		)
	},
)
Button.displayName = `Button`

export { Button, buttonVariants }
